import 'jquery'
import 'popper.js'
import 'bootstrap'
import AutoNumeric from 'autonumeric';

import Glide from '@glidejs/glide'
import HSUnfold from '../front_ui/vendor/hs-unfold/src/js/hs-unfold'

window.Glide = Glide;
window.AutoNumeric = AutoNumeric;
window.ionRangeSlider = require("ion-rangeslider/js/ion.rangeSlider");
window.ClipboardJS = require("clipboard");
window.Sharer = require("sharer.js/sharer");
window.HSUnfold = HSUnfold;
window.Typed = require("typed.js");

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

require('@client-side-validations/client-side-validations')
require('@client-side-validations/simple-form')
require('@client-side-validations/simple-form/dist/simple-form.bootstrap4')
require('jquery-multifile')

require('bootstrap-input-spinner');
require('lightgallery');

require('select2/dist/js/select2');
require('select2/dist/js/i18n/id.js');

require('@fancyapps/fancybox')
require('../front_ui/js/hs.core')
require('../front_ui/js/hs.fancybox')
require('../front_ui/js/hs.typed')

require.context('../images', true);

const idrNumberFormat = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 });
window.idrNumberFormat = function (number) {
  return idrNumberFormat.format(number)
};

import 'stylesheets/application'

